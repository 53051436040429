import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/IndexHome.vue"),
  },
  {
    path: "/play/:id/:name/:episode",
    name: "PlayVideo",
    component: () => import("../views/PlayVideo.vue"),
  },
  {
    path: "/playlets",
    name: "playlets",
    component: () => import("../views/playlets.vue"),
  },
  {
    path: "/audio",
    name: "AudioList",
    component: () => import("../views/AudioList.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 };
  },
});

export default router;
