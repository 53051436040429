<template>
  <div class="containerView">
    <router-view />
  </div>
  <footer class="footer">
    <p>© 2023 NovaBeats Video.</p>
  </footer>
</template>

<script setup>
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}
.containerView {
  @apply md:w-[1080px] md:min-w-[1080px] md:m-auto;
}
.footer {
  @apply bg-gray-200 text-gray-700 text-[.32rem] py-2 text-center mt-2 md:text-[14px] md:py-[15px];
}
</style>
